import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { EventTypes, GameMode, ISettledBet } from '../../global.d';
import {
  setAnticipation,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBlocksLoading,
  setBonusGameDataRequested,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentIsTurboSpin,
  setGameMode,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsOpenAutoplayModal,
  setIsOpenBetSettingsModal,
  setIsPopupOpened,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setIsTurboSpin,
  setLastRegularWinAmount,
  setReplayBet,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import type { IConfig, ISlotConfig, IStressful } from '../../gql/d';
import { placeBetGql } from '../../gql/mutation';
import {
  getAutoSpinsGql,
  getBetAmountGql,
  getSlotLoadProgressInfoGql,
  isStoppedGql,
  replayBetGql,
  slotConfigGql,
  stressfulGql,
} from '../../gql/query';
import { Logic } from '../../logic';
import { eventManager } from '../../slotMachine/config';
import type { LineSet } from '../../slotMachine/d';
import {
  canPressSpin,
  checkPhoenixAnticipation,
  handleChangeRestriction,
  isRegularMode,
  showCurrency,
} from '../../utils';

import type { IPlaceBetInput } from './d';

let timeout: ReturnType<typeof setTimeout>;

const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(slotConfigGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { data: stressful } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { slotId, id } = useReactiveVar<ISlotConfig>(setSlotConfig).lineSets[0] as LineSet;
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const userBalance = useReactiveVar(setUserBalance);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getSlotLoadProgressInfoGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const balanceAmount = userBalance?.balance.amount || 0;

  const { progress } = dataProgress!;

  const betCompleteCallback = (placeBet: ISettledBet): void => {
    const metadata = placeBet.balance.settled.metadata || placeBet.balance.placed.metadata;

    if (metadata) {
      setStressful({
        show: true,
        message: metadata.data.messageBody,
        title: metadata.data.messageTitle,
        type: metadata.type === 'BLOCKING' ? 'blocking' : 'info',
      });
    }

    eventManager.emit('placeBetCompleted');
    setCurrentIsTurboSpin(setIsTurboSpin());
    const bonus = placeBet.bet.data.features;
    let bonusGame = null;
    if (placeBet.bet.userBonus) {
      if (bonus.lightningLotteryResult?.length) {
        bonusGame = bonus.lightningLotteryResult;
        const rounds = bonusGame.filter((e) => e.type !== 'Failed').length;
        setCurrentBonus({
          ...setCurrentBonus(),
          rounds,
        });
      } else {
        bonusGame = bonus.specialShotLotteryResult;
      }
    }
    const showPhoenix = checkPhoenixAnticipation(placeBet);
    if (!placeBet.bet.userBonus && showPhoenix) {
      setAnticipation(true);
      eventManager.emit(EventTypes.PHOENIX_START);
      eventManager.once(EventTypes.PHOENIX_END, () => {
        setResult(placeBet);
        eventManager.emit(EventTypes.START_SPIN_ANIMATION);
        setAnticipation(false);
      });
    } else {
      setResult(placeBet);
    }
  };

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const setResult = (placeBet: ISettledBet): void => {
    Logic.the.controller.setResult(placeBet);
    if (Logic.the.isStopped()) {
      Logic.the.quickStop();
    }
  };

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError() {
      eventManager.emit('placeBetCompleted');
    },

    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet);
    },
  });

  const [getReplayBet] = useLazyQuery<{ placeBet: ISettledBet }, { betId: string }>(replayBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet);
    },
    onError() {
      eventManager.emit('placeBetCompleted');
    },
  });

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      clearTimeout(timeout);
      const freeRoundBonus = setCurrentBonus().gameMode === GameMode.FREE_ROUND_BONUS && setCurrentBonus().isActive;
      if (AudioApi.isRestricted) {
        AudioApi.changeRestriction(
          false,
          [],
          () => setIsSoundLoading(true),
          () => {
            handleChangeRestriction(Logic.the.controller.gameMode);
            setIsShowSoundToast(false);
          },
        );
      }
      if (Logic.the.isReadyToStop()) {
        AudioApi.play({ type: ISongs.SFX_UI_SpinStop, stopPrev: true });
        Logic.the.quickStop();
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
        return;
      }
      if (Logic.the.isReadyToSkip()) {
        Logic.the.skipWinAnimation();
        return;
      }
      if (Logic.the.isReadyToSpin()) {
        setCurrentIsTurboSpin(isTurboSpin);
        setLastRegularWinAmount(0);
        Logic.the.spin();
        if (!freeRoundBonus) {
          setWinAmount(0);
          eventManager.emit(
            EventTypes.UPDATE_WIN_VALUE,
            formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
          );
        }
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        if (setReplayBet()) {
          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          const payload = {
            variables: {
              input: {
                slotId,
                coinAmount: setCoinAmount(),
                coinValue: setCoinValue(),
                lineSetId: id,
              } as IPlaceBetInput,
            },
          };
          if (freeRoundBonus) {
            payload.variables.input.userBonusId = setCurrentBonus().id;
          }
          fnGet(payload);
        }
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        setIsOpenAutoplayModal(false);
        setIsOpenBetSettingsModal(false);
        setIsPopupOpened(false);
        eventManager.emit(EventTypes.RESET_GAME_PROGRESS);
        eventManager.emit(EventTypes.CLEAN_UP_REEL);
        eventManager.emit(EventTypes.THOR_REQUEST_BLOCKS);
        AudioApi.stop({ type: ISongs.SFX_UI_Close });
        AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
        if (AudioApi.isRestricted) {
          handleChangeRestriction(Logic.the.controller.gameMode);
        }
      }
    },
    [dataBet?.betAmount, fnGet, slotId, id, slotId],
  );
  useEffect(() => {
    const bonusGame = () => {
      setCurrentIsTurboSpin(setIsTurboSpin());
      clearTimeout(timeout);
      Logic.the.spin();
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      if (setReplayBet()) {
        getReplayBet({
          variables: { betId: setReplayBet() },
        });
      } else {
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: id,
              userBonusId: setCurrentBonus().id,
            },
          },
        });
      }
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
    };
    eventManager.on(EventTypes.ENTER_BONUS_GAME, bonusGame);
    return () => {
      eventManager.removeListener(EventTypes.ENTER_BONUS_GAME, bonusGame);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins() && !stressful?.stressful.show) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus =
        setIsStopOnFeatureWin() &&
        setCurrentBonus().isActive &&
        setCurrentBonus().gameMode !== GameMode.FREE_ROUND_BONUS;
      const stopOnWin = setIsStopOnAnyWin() && setLastRegularWinAmount() > 0;

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;
      if (autoSpinsLeft || bonus || stopOnWin || stopOnWinExceeds || balanceIncrease || balanceDecrease) {
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin, stressful?.stressful]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (
      (isRegularMode(setGameMode()) || Logic.the.controller.gameMode === GameMode.BUY_FEATURE) &&
      setIsFreeSpinsWin()
    ) {
      return;
    }
    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      if (!setIsSlotBusy()) {
        eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
      }
    } else {
      onSpin(isTurboSpin);
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32 && !stressful?.stressful.show) {
        e.preventDefault();
        e.stopPropagation();

        if (data?.isEnabledSpaceSpin) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          eventManager.emit(EventTypes.SPACEKEY_CLICKED);
          if (Logic.the.isReadyToSkip()) {
            Logic.the.skipWinAnimation();
            return;
          }
        }
        if (
          !canPressSpin({
            gameMode: Logic.the.controller.gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            bonusCurrentRound: setCurrentBonus().currentRound,
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isPopupOpened: setIsPopupOpened(),
            isBlocksLoading: setBlocksLoading(),
          })
        ) {
          return;
        }
        if (setBonusGameDataRequested()) {
          return;
        }

        if (data?.isEnabledSpaceSpin && isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (data?.isEnabledSpaceSpin) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful?.stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  const isSlotBusy = useReactiveVar(setIsSlotBusy);
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped && !isSlotBusy) {
      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped, isSlotBusy]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return <></>;
};

export default Spin;
