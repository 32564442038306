import { ITrackEntry } from 'pixi-spine';

import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/d';
import { Icon, LineSet, ThorRewards } from './slotMachine/d';

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IBalanceMetadata {
  type: string;
  data: {
    messageTitle: string;
    messageBody: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface Cascade {
  isRandomWilds: boolean;
  winPositions: number[][];
  winAmounts: number[];
  cascadeFall: (SlotId | '')[][];
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      bonuses: UserBonus[];
      features: {
        generatedReelSet: GeneratedReelSet[];
        lightningLotteryResult: BonusGameResult[];
        specialShotLotteryResult: BonusGameResult;
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
      metadata?: IBalanceMetadata;
    };
    settled: {
      amount: number;
      currency: string;
      metadata?: IBalanceMetadata;
    };
  };
}
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};

export interface GeneratedReelSet {
  iconId: SlotId;
  isLost: boolean;
  rewardName: ThorRewards | null;
}

export interface BonusGameResult {
  type: string;
  value: string;
}

export interface LightningEntryAnimation extends ITrackEntry {
  animation: {
    duration: number;
    name: string;
  };
}

export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  gameMode: GameMode;
  currentRound: number;
  betId: string;
  coinAmount: number;
  coinValue: number;
  rounds: number;
  roundsPlayed: number;
  data: {
    cascadeMultiplier?: number;
    specialShotValues?: string[];
    firstLotteryValues?: string[];
    frbReferenceId: string | null;
  };
  status: BonusStatus;
  isFreeBet?: boolean;
  cascadeMultiplier: number;
  reelSetId?: string;
  totalWinAmount: number;
  isFinished: boolean;
};
export enum GameMode {
  BASE_GAME,
  LIGHTNING_BONUS,
  SPECIAL_SHOT_BONUS,
  BUY_FEATURE,
  FREE_ROUND_BONUS,
}

export type BuyFeatureEnterProps = {
  bonusId: string;
};
export type FreeSpinsEnterProps = {
  bonus: UserBonus;
};
export type EnterProps = (BuyFeatureEnterProps | FreeSpinsEnterProps) & {
  immediate?: boolean;
  endBonus?: boolean;
  skipIdle?: boolean;
};

export enum FeatureState {
  LIGHTNING_BONUS,
  SPECIAL_SHOT_BONUS,
}
export const baseReelSetIds = [
  '0a2829d2-0bd8-4a20-8e96-dfaff8c318dd',
  'd342aa2c-e1c4-4fe5-80c4-433874a1df53',
  'df31e730-6cbc-4195-bf12-b9be7ccec944',
];
export const bonusesId = {
  [GameMode.LIGHTNING_BONUS]: 'e1094c83-d597-4f40-abe8-05c53e263807',
  [GameMode.FREE_ROUND_BONUS]: '1e716f58-d5de-45a7-bb73-a72e0f827654',
};
export const buyFeatureBonusesId = {
  [FeatureState.LIGHTNING_BONUS]: '583982b9-dd48-4d28-b3a9-9f70e4deffd5',
  [FeatureState.SPECIAL_SHOT_BONUS]: '41263693-629c-4ea9-8b9f-36329710c185',
};
export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
  isActive: boolean;
  bonusId: string;
  roundsPlayed: number;
  currentRound: number;
  rounds: number;
  reelSetId: string;
  totalWinAmount: number;
  cascadeMultiplier: number;
  betId: string;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export type MessageBannerProps = {
  title?: string;
  titlePosition?: number;
  mobileTitlePosition?: number;
  titleStyles?: TextStyle;
  mobileTitleStyle?: TextStyle;
  subtitle?: string;
  subtitlePosition?: number;
  mobileSubtitlePosition?: number;
  subtitleStyles?: TextStyle;
  mobileSubtitleStyle?: TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: TextStyle;
  mobileAdditionalStyle?: TextStyle;
  preventDefaultDestroy?: boolean;
  mobileSubtitleBorderWidth?: number;
  mobileSubtitleBorderHeight?: number;
  subtitleBorderWidth?: number;
  subtitleBorderHeight?: number;
  callback?: () => void;
  onInitCallback?: () => void;
};

export enum EventTypes {
  BROKEN_GAME = 'brokenGame',
  END_BIG_WIN_PRESENTATION = 'endBigWinPresentation',
  CHANGE_MODE = 'changeMode',
  COUNT_UP_END = 'countUpEnd',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_LIGHTNING_BONUS = 'endLightningBonus',
  END_SPECIAL_SHOT_BONUS = 'endSpecialShotBonus',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  FORCE_STOP_REELS = 'forceStopReels',
  FORCE_RESIZE = 'forceResize',
  GAME_READY = 'gameReady',
  CLEAN_UP_REEL = 'cleanUpReel',
  HIDE_COUNT_UP = 'hideCountUp',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_BONUS_GAME_TITLE = 'handleUpdateBonusGameTitle',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  NEXT_CASCADE = 'nextCascade',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  REELS_STOPPED = 'reelsStopped',
  RESET_REELS_ANIMATION_END = 'resetReelsAnimationEnd',
  RESET_REELS_ANIMATION_START = 'resetReelsAnimationStart',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  ROLLBACK_REELS = 'rollbackReels',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_CASCADE_WIN_ANIMATION = 'startCascadeWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  START_COUNT_UP = 'startCountUp',
  START_MULTIPLIER_ANIMATION = 'startMultiplierAnimation',
  START_BIG_WIN_PRESENTATION = 'startBigWinPresentation',
  SHOW_WIN_LABEL = 'showWinLabel',
  SOUND_INITIALIZED = 'soundInitialized',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_POPUP = 'openPopup',
  OPEN_POPUP_BG = 'openPopupBg',
  CLOSE_POPUP = 'closePopup',
  CLOSE_POPUP_BG = 'closePopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  THOR_IDLE = 'thorIdle',
  THOR_ATTACK = 'thorAttack',
  THOR_AFTER_ATTACK = 'thorAfterAttack',
  THOR_W_POWER_IN = 'thorWPowerIn',
  THOR_W_POWER_OUT = 'thorWPowerOut',
  THOR_W_POWER_IDLE = 'thorWPowerIdle',
  THOR_GUARANTEE_IN = 'thorGuaranteeIn',
  THOR_AFTER_SUPER_POWER_IN = 'thorAfterSuperPowerIn',
  THOR_GUARANTEE_OUT = 'thorGuaranteeOut',
  THOR_GUARANTEE_IDLE = 'thorGuaranteeIdle',
  UPDATE_GAME_PROGRESS_ICON = 'updateGameProgressIcon',
  UPDATE_MULTIPLIER = 'updateMultiplier',
  UPDATE_GAME_PROGRESS = 'updateGameProgress',
  RESET_GAME_PROGRESS = 'resetGameProgress',
  THOR_REQUEST_BLOCKS = 'thorRequestBlocks',
  THOR_REQUEST_BLOCKS_LIGHTNING = 'thorRequestBlocksLightning',
  PHOENIX_START = 'phoenixStart',
  PHOENIX_END = 'phoenixEnd',
  SPECIAL_THOR_IN = 'specialThorIn',
  SPECIAL_THOR_READY = 'specialThorReady',
  SPECIAL_THOR_START = 'specialThorStart',
  LIGHTNING_THOR_IN = 'lightningBonusIn',
  LIGHTNING_THOR_START = 'lightningThorStart',
  LIGHTNING_PUSH_CLICKED = 'lightningPushClicked',
  SPECIAL_SHOT_PUSH_CLICKED = 'specialShotPushClicked',
  SHOW_LIGHTNING_PUSH = 'showLightningPush',
  SHOW_LIGHTNING_PUSH_ANIMATION = 'showLightningPushAnimation',
  HIDE_LIGHTNING_PUSH = 'hideLightningPush',
  HIDE_LIGHTNING_FINAL_MULTIPLIER = 'hideLightningFinalMultiplier',
  LIGHTNING_BONUS_DATA = 'lightningBonusData',
  SPECIAL_SHOT_BONUS_DATA = 'specialShotBonusData',
  START_LIGHTNING_BONUS_GAME = 'startLightningBonusGame',
  START_SPECIAL_SHOT_BONUS_GAME = 'startSpecialShotBonusGame',
  START_LIGHTNING_BONUS_WIN_PRESENTATION = 'startLightningBonusWinPresentation',
  START_SpecialShot_BONUS_WIN_PRESENTATION = 'startSpecialShotBonusWinPresentation',
  CURRENT_LIGHTNING_MULTIPLIER = 'currentLightningMultiplier',
  ENTER_BONUS_GAME = 'enterBonusGame',
  EXECUTE_SPECIAL_SHOT = 'executeSpecialShot',
  CHANGE_BONUS_GAME_ZINDEX = 'changeBonusGameZIndex',
  SPACEKEY_CLICKED = 'spaceKeyClicked',
  UPDATE_SIZE = 'updateSize',
  START_TRACK_ANIMATION = 'startTrackAnimation',
  SKIP_TRACK_ANIMATION = 'skipTrackAnimation',
  SET_REMOVED_SLOT_COORDINATES = 'setRemovedSlotCoordinates',
  RESET_THOR_ANIMATION_SPEED = 'resetThorAnimationSpeed',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  CHANGE_STATE = 'changeState',
  FREE_ROUND_BONUS_EXPIRED = 'freeRoundBonusExpired',
  START_FREE_ROUND_BONUS = 'startFreeRoundBonus',
  END_FREE_ROUND_BONUS = 'endFreeRoundBonus',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export enum SpecialShotBonusColors {
  DEFAULT = 'DEFAULT',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  RED = 'RED',
}
